<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("newManualIncoming.addNewIncome") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start m-2 custom-input">
            <div class="mb-3 whitebg-input" v-if="!type">
              <label class="form-label bold-12 text-gray">{{
                $t("newManualIncoming.incomeType")
              }}</label>
              <v-select
                :options="typeOptions"
                v-model="newIncoming.type"
                v-on:option:selected="loadAddTo"
                label="name"
                :placeholder="$t(`finance.notSelected`)"
                :reduce="(c) => c.id"
                append-to-body
              />
            </div>
            <div class="mb-3 whitebg-input" v-else-if="allowForceMaterials">
              <label class="cstm-check d-flex">
                <span class="regular-12 text-black mx-3">
                  {{ $t("menu.materials") }}
                </span>
                <input type="checkbox" v-model="forceMaterials" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div v-if="type === 4">
              <v-select
                :options="[
                  {
                    id: 0,
                    name: 'Deduction',
                  },
                  {
                    id: 1,
                    name: 'Increase',
                  },
                ]"
                v-model="increase"
                label="name"
                :placeholder="$t(`finance.notSelected`)"
                :reduce="(c) => c.id"
                append-to-body
              />
            </div>
            <div class="mb-3 whitebg-input">
              <label class="form-label bold-12 text-gray">{{
                $t("filters.warehouse")
              }}</label>
              <v-select
                :options="warehouses"
                v-model="newIncoming.warehouse_id"
                label="name"
                :placeholder="$t(`finance.notSelected`)"
                v-on:option:selected="loadAddTo"
                :reduce="(c) => c.id"
                disabled
                append-to-body
              />
            </div>
            <div
              class="mb-3 white-input"
              v-if="
                newIncoming.type === 2 ||
                newIncoming.type === 5 ||
                newIncoming.type === 9
              "
            >
              <label class="form-label bold-12 text-gray">{{
                $t("crmPaymentPairingModal.partner")
              }}</label>
              <CrmItemFinder
                type="partners"
                :always-input="true"
                v-model="newIncoming.partner_id"
                :reduce="(c) => c.id"
                :products="addProducts.map((e) => e.id)"
              />
            </div>
            <div
              class="mb-3 white-input"
              v-if="newIncoming.type === 6 || newIncoming.type === 2"
            >
              <label
                class="form-label bold-12 text-gray"
                v-if="newIncoming.type === 6"
              >
                {{ $t("newManualIncoming.productionBy") }}
              </label>
              <label
                class="form-label bold-12 text-gray"
                v-else-if="newIncoming.type === 2"
              >
                {{ $t("newManualIncoming.ingredientsFrom") }}
              </label>
              <v-select
                :options="
                  warehouses.filter(
                    (e) =>
                      (newIncoming.type === 2 && e.type === 1) || e.type === 2
                  )
                "
                v-model="newIncoming.warehouse_outgoing_id"
                label="name"
                :placeholder="$t(`finance.notSelected`)"
                :reduce="(c) => c.id"
                v-on:option:selected="loadAddTo"
                append-to-body
              />
            </div>
            <div
              class="mb-3 white-input"
              v-if="
                newIncoming.type === 2 ||
                newIncoming.type === 5 ||
                newIncoming.type === 6 ||
                newIncoming.type === 9
              "
            >
              <label class="form-label bold-12 text-gray">{{
                $t("newManualIncoming.addTo")
              }}</label>
              <v-select
                :options="incomes"
                v-model="newIncoming.income_id"
                label="pretty_id"
                :placeholder="$t(`newManualIncoming.selectANewIncome`)"
                :reduce="(c) => c.id"
                append-to-body
              />
            </div>
            <div
              class="row custom-input whitebg-input"
              v-for="p in addProducts"
              :key="p.id"
            >
              <div class="col-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("ingredients.quantity")
                }}</label>
                <input
                  type="number"
                  v-model="p.quantity"
                  class="form-control"
                  min="1"
                />
              </div>
              <div
                :class="
                  selectedWarehouse?.managment_type == 2 &&
                  [4, 7, 8].indexOf(newIncoming.type) > -1
                    ? 'col-6'
                    : 'col-9'
                "
              >
                <div class="mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("chart1.product")
                  }}</label>
                  <v-select
                    v-model:options="p.products"
                    v-on:search="(s, k) => fetchProducts(s, k, p)"
                    v-model="p.id"
                    :filterable="false"
                    :placeholder="$t(`copyProducts.selectProduct`)"
                    :reduce="(c) => c.variant_id"
                    :getOptionKey="(c) => c.variant_id"
                    v-on:option:selected="(e) => changeProducts(e.variant_id)"
                    v-on:option:deselected="() => changeProducts(0)"
                    append-to-body
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching && search.length > 2">
                        {{ $t("copyProducts.noProductsFoundFor") }}
                        <em>{{ search }}</em
                        >.
                      </template>
                      <span v-else>
                        {{
                          $t(`newManualIncoming.startTypingToSearchForA`, {
                            expr:
                              newIncoming.type === 5 ? "material" : "product",
                          })
                        }}</span
                      >
                    </template>
                    <template v-slot:selected-option="option">
                      <div class="row">
                        <div class="col-auto px-0">
                          <img
                            :src="option?.image"
                            v-if="
                              option?.image !== null &&
                              option?.image !== undefined
                            "
                            class="product-image"
                            alt=""
                          />
                          <BaseIcon
                            v-else
                            name="no-image"
                            class="product-image"
                          />
                        </div>
                        <div class="col">
                          <div>
                            <b>{{ option.name }}</b>
                          </div>
                          <div>
                            <span
                              v-for="char in option.caracteristics"
                              :key="char"
                              class="me-2"
                            >
                              {{ char }}
                            </span>
                            <span class="me-2">
                              {{ option.serial }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      <span class="me-2">
                        {{ option.serial }}
                      </span>
                      <span
                        v-for="char in option.caracteristics"
                        :key="char"
                        class="me-2"
                      >
                        {{ char }}
                      </span>
                      <b>{{ option.name }}</b>
                    </template>
                  </v-select>
                </div>
              </div>
              <div
                class="col-3"
                v-if="
                  selectedWarehouse?.managment_type == 2 &&
                  [4, 7, 8].indexOf(newIncoming.type) > -1
                "
              >
                <label class="form-label bold-12 text-gray">
                  Slot number
                </label>
                <input
                  type="text"
                  v-model="p.slot_number"
                  class="form-control"
                  placeholder="Slot number"
                />
              </div>
              <div class="col-12" v-if="showDescription">
                <label class="form-label bold-12 text-gray">{{
                  $t("meta.description")
                }}</label>
                <textarea
                  v-model="p.description"
                  class="form-control"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-6">
              <label class="cstm-check d-flex" for="ct-1">
                <span class="regular-12 text-black mt-3">
                  {{ $t("newManualIncoming.addDescription") }}
                </span>
                <input type="checkbox" v-model="showDescription" id="ct-1" />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
            <div class="col-6 text-end">
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="addIncome"
                :class="{ 'loading-spin': loadAddBtn }"
                :disabled="loadAddBtn"
              >
                {{ $t("finance.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import { useStore } from "vuex";
import barcodeRead from "../../modules/barcodeRead";
import BaseIcon from "../icons/BaseIcon";
const bootstrap = require("bootstrap");
import ItemOperationTypes from "@/modules/ItemOperationTypes";
import CrmItemFinder from "../lists/listItems/CrmItemFinder.vue";

export default {
  name: "NewManualIncoming",
  props: {
    modelValue: Object,
    type: Number,
  },
  emits: ["update:modelValue", "newItem"],
  data() {
    return {
      store: useStore(),
      showDescription: false,
      myModal: undefined,
      lastSearchId: 0,
      itemOperationTypes: ItemOperationTypes,
      success: false,
      loadAddBtn: false,
      addProducts: [{ id: null, quantity: 1, products: [] }],
      forceMaterials: false,
      allowForceMaterials: false,
      newIncoming: {
        warehouse_id: this.warehouse,
      },
      increase: 0,
      incomes: [],
      mainCharacteristics: [],
    };
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    partner() {
      return this.newIncoming.partner_id;
    },
    typeOptions() {
      if (this.selectedWarehouse?.type === 3) {
        return [
          { name: "Product", id: 2 },
          { name: "Production", id: 6 },
        ];
      }

      if (this.selectedWarehouse?.type === 2) {
        return [{ name: "Material", id: 5 }];
      }

      return [
        { name: "Product", id: 2 },
        { name: "Material", id: 5 },
        { name: "Production", id: 6 },
      ];
    },
  },
  mounted() {
    this.newIncoming.warehouse_id = this.warehouse;
    this.allowForceMaterials = this.selectedWarehouse?.type === 1;
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });

    barcodeRead.load(this.barcodeReaded);
  },
  unmounted() {
    barcodeRead.unload();
  },
  watch: {
    warehouse() {
      this.newIncoming.warehouse_id = this.warehouse;
    },
    selectedWarehouse() {
      this.allowForceMaterials = this.selectedWarehouse?.type === 1;
    },
    type() {
      this.newIncoming.type = this.type;
      this.loadAddTo();
    },
    partner() {
      this.loadAddTo();
    },
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      console.log("ASD");
      console.log(this.myModal);
      this.myModal.show();
    },
    loadAddTo() {
      if (
        this.newIncoming.type &&
        this.newIncoming.warehouse_id &&
        (this.newIncoming.partner_id || this.newIncoming.warehouse_outgoing_id)
      ) {
        http
          .fetch("/warehouses/income/search", {
            type: this.newIncoming.type,
            status: -3,
            warehouse_id: this.newIncoming.warehouse_id,
            partner_id: this.newIncoming.partner_id ?? "",
            outgoing_id: this.newIncoming.warehouse_outgoing_id ?? "",
          })
          .then((data) => {
            this.incomes = data.data;
          });
      }
    },
    barcodeReaded(code) {
      http
        .fetch(
          `/products/${
            this.selectedWarehouse?.type === 2 ||
            this.newIncoming.type === 5 ||
            this.forceMaterials
              ? "materials"
              : "products"
          }/search`,
          { q: code }
        )
        .then((data) => {
          if (data.length > 0) {
            this.addProducts[0].products = data;
            this.addProducts[0].id = data[0].variant_id;
            this.changeProducts(data[0].variant_id);
          }
        });
    },
    setCharacteristics(cs) {
      if (this.mainCharacteristics.length === 0) {
        this.mainCharacteristics = cs.filter((e) => e.is_variant);
      }
    },
    setEmpty() {
      this.addProducts = [{ id: null, quantity: 1, products: [] }];
    },
    addProductVariant(variant, quantity = 1) {
      let cs = [];

      if (this.mainCharacteristics?.length < 1) {
        for (const key in variant) {
          if (key.indexOf("characteristics_") === 0) {
            this.mainCharacteristics.push({
              id: key.replace("characteristics_", ""),
            });
          }
        }
      }

      for (const c of this.mainCharacteristics) {
        if (c?.characteristics_values) {
          cs.push(
            c.characteristics_values.find(
              (e) => e.id === variant["characteristics_" + c["id"]]
            )?.value
          );
        } else {
          cs.push(variant["characteristics_" + c["id"]]);
        }
      }
      this.addProducts[0].products = [
        {
          variant_id: variant.variant_id ?? variant.id,
          name: variant.name ?? variant.name_en,
          serial: variant?.serial,
          image: variant?.image,
          caracteristics: cs,
        },
      ];
      this.addProducts[0].id = variant.variant_id ?? variant.id;
      this.addProducts[0].quantity = quantity;
      this.changeProducts(variant.variant_id ?? variant.id);
    },
    changeProducts(variant_id) {
      let hasNull = false;
      let quantity = 0;
      let count = 0;
      for (const p in this.addProducts) {
        if (this.addProducts[p].id === null) {
          if (p !== 0) {
            this.addProducts.splice(p, 1);
          } else {
            hasNull = true;
          }
        } else if (this.addProducts[p].id === variant_id) {
          count++;
          quantity += this.addProducts[p].quantity;
          if (count > 1) {
            this.addProducts.splice(p, 1);
          }
        }
      }
      if (count > 1) {
        for (const p in this.addProducts) {
          if (this.addProducts[p].id === variant_id) {
            this.addProducts[p].quantity = quantity;
            break;
          }
        }
      }
      if (!hasNull) {
        this.addProducts.unshift({ id: null, quantity: 1, products: [] });
      }
    },
    addIncome() {
      this.loadAddBtn = true;
      let incoming = Object.assign({}, this.newIncoming);
      incoming.multiple_delivery = incoming.multiple_delivery ? 1 : 0;
      incoming.items = [];

      for (const product of this.addProducts) {
        if (product.id !== null && product.id !== undefined) {
          incoming.items.push({
            id: product.id,
            quantity: product.quantity,
            description: product?.description ?? null,
            slot_number: product?.slot_number ?? null,
          });
        }
      }

      http
        .fetch(
          `/warehouses/${
            this.itemOperationTypes[this.newIncoming.type].type === "income" ||
            this.increase === 1
              ? "income"
              : "outgoing"
          }`,
          incoming,
          true
        )
        .then((data) => {
          this.$emit("newItem", data);
          this.myModal.hide();
          this.loadAddBtn = false;
          this.increase = 0;
          this.addProducts = [{ id: null, quantity: 1, products: [] }];
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    fetchProducts(search, loading, p) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      let lastSearchId = Math.random();
      this.lastSearchId = lastSearchId;
      http
        .fetch(
          `/products/${
            this.selectedWarehouse?.type === 2 ||
            this.newIncoming.type === 5 ||
            this.forceMaterials
              ? "materials"
              : "products"
          }/search`,
          { q: search }
        )
        .then((data) => {
          if (this.lastSearchId === lastSearchId) {
            p.products = data;
            loading(false);
          }
        })
        .catch(() => {
          loading(false);
        });
    },
  },
  components: { CrmItemFinder, BaseIcon },
};
</script>
